<template>
  <div class="player">
    <div class="thumbnail-wrapper">
      <img class="thumbnail" :src="thumbnailUrl" />
    </div>
    <div class="mask"></div>
    <div class="button-mask">
      <img @click="startVideo" src="@/assets/img/play-btn.svg" />
    </div>

    <section class="preview-screen" v-if="videoStart">
      <div class="w-full">
        <div class="flex justify-between title-wrapper">
          <img @click="close" src="@/assets/img/close-icon.svg" />
          <p v-if="remainingSeconds > 0" class="text-white">還有 {{ remainingSeconds }} 秒</p>
          <p v-else class="text-white">已完成</p>
        </div>
      </div>
      <div class="player-block">
        <div id="youtube-player">
        </div>
        <!-- <iframe
          id="youtube-player"
          width="560"
          height="315"
          :src="embedUrl"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe> -->
      </div>
      <div v-if="isPaused" class="start-mask">
        <div class="buttons">
          <button
            class="fM rounded start-button"
            @click="playVideo"
          >
            繼續播放
          </button>
        </div>
      </div>
    </section>
    <ConfirmDialog
      ref="leaveDialogRef"
      type="info"
      title="確定要離開影片嗎？"
      content="影片未看完無法領取獎勵"
      :buttons="leaveDialogButtons"
    />
  </div>
</template>

<script>
import ConfirmDialog from '@/components/Dialog/ConfirmDialog.vue'

export default {
  name: "Player",
  components: {
    ConfirmDialog,
  },
  props: {
    youtubeUrl: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    getAwardDuration: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      videoId: "",
      player: null,
      embedUrl: "",
      videoStart: false,
      intervalId: null,
      currentTime: 0,
      videoDuration: 0,
      isPaused: false,
    };
  },
  mounted() {
    window.onYouTubeIframeAPIReady = this.onYouTubeIframeAPIReady;
  },
  watch: {
    youtubeUrl: {
      immediate: true,
      handler() {
        this.embedUrl = this.convertToEmbedUrl(this.youtubeUrl)
      }
    },
    videoStart(){
      if (!this.videoStart) {
        return
      }

      this.$nextTick(() => {
        this.onYouTubeIframeAPIReady();
      });
    }
  },
  computed: {
    leaveDialogButtons() {
      return [
        {
          text: "離開",
          outline: true,
          click: ({ close }) => {
            close()
            this.stopVideo();
            this.isPaused = false
          },
        },
        {
          text: "繼續播放",
          click: ({ close }) => {
            close()
            this.playVideo();
          },
        },
      ];
    },
    thumbnailUrl() {
      try {
        return this.getYoutubeThumbnail(this.youtubeUrl);
      } catch (error) {
        console.error(error);
        return "";
      }
    },
    remainingSeconds() {
      return this.getAwardDuration - this.currentTime;
    }
  },
  methods: {
    close() {
      if (this.currentTime < this.getAwardDuration) {
        this.pauseVideo()
        this.$refs.leaveDialogRef.open();
      } else {
        this.finishVideo()
      }
    },
    finishVideo() {
      this.$emit('finish');
      this.stopVideo();
    },
    currentTimeInterval() {
      if (this.intervalId) {
        clearInterval(this.intervalId);
      }
      this.intervalId = setInterval(() => {
        this.updateCurrentTime();
      }, 1000);
    },
    pauseVideo() {
      this.player.pauseVideo();
    },
    playVideo() {
      this.player.playVideo();
    },
    onYouTubeIframeAPIReady() {
      this.player = new YT.Player('youtube-player', {
        height: '315',
        width: '560',
        videoId: this.videoId,
        playerVars: {
          'autoplay': 1,
          'controls': 0,
          'disablekb': 1,
          'modestbranding': 1,
          'rel': 0,
          'showinfo': 0,
        },
        events: {
          'onReady': this.onPlayerReady,
          'onStateChange': this.onPlayerStateChange,
        },
      });
    },
    onPlayerReady(event) {
      event.target.playVideo();
      this.getVideoDuration();
    },
    onPlayerStateChange(event) {
      if (event.data === YT.PlayerState.PLAYING) {
        // 當影片開始播放時，開始更新時間
        this.currentTimeInterval()
      }
      if (event.data === YT.PlayerState.ENDED) {
        // 當影片播放結束時，停止更新時間
        this.finishVideo()
      }
      if (event.data === YT.PlayerState.PAUSED) {
        // 當影片暫停時，停止更新時間
        this.isPaused = true
      } else {
        this.isPaused = false
      }
    },
    updateCurrentTime() {
      console.log('this.player.getCurrentTime()', this.player.getCurrentTime());
      if (this.player && this.player.getCurrentTime) {
        this.currentTime = Math.floor(this.player.getCurrentTime());
      }
    },
    getVideoDuration() {
      if (this.player && this.player.getDuration) {
        this.videoDuration = Math.floor(this.player.getDuration());
      }
    },
    startVideo() {
      if (this.videoStart) return
      if (this.disabled) return
      this.videoStart = true;
    },
    stopVideo() {
      this.videoStart = false;
      this.player = null;
      this.currentTime = 0;
      this.videoDuration = 0;
      this.intervalId && clearInterval(this.intervalId);
      this.intervalId = null;
    },
    getYoutubeThumbnail(url) {
      const urlObj = new URL(url);

      // 確認是 YouTube 的網址
      if (urlObj.hostname !== "www.youtube.com" && urlObj.hostname !== "youtube.com") {
        throw new Error("這不是一個有效的 YouTube 網址");
      }

      // 取得影片 ID
      const videoId = urlObj.searchParams.get("v");
      if (!videoId) {
        throw new Error("無法從網址中取得影片 ID");
      }

      // 構建縮圖 URL
      return `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;
    },
    convertToEmbedUrl(url) {
      try {
        const urlObj = new URL(url);

        // 確認是 YouTube 的網址
        if (!urlObj.hostname.includes("youtube.com") && !urlObj.hostname.includes("youtu.be")) {
          throw new Error("這不是一個有效的 YouTube 網址");
        }

        // 處理不同域名的情況（支持 youtu.be 短鏈）
        let videoId;
        if (urlObj.hostname === "youtu.be") {
          // 短鏈模式，ID 在 pathname
          videoId = urlObj.pathname.substring(1);
        } else {
          // 標準網址模式，ID 通過 searchParams 提取
          videoId = urlObj.searchParams.get("v");
        }

        if (!videoId) {
          throw new Error("無法從網址中取得影片 ID");
        }

        this.videoId = videoId;

        // 構建嵌入式的 URL
        let embedUrl = `https://www.youtube.com/embed/${videoId}`;

        // 取得其他參數，例如 list 和 start_radio
        const params = new URLSearchParams();

        // 添加基本控制參數，禁用控制按鈕、鍵盤控制、隱藏品牌標識
        params.append("controls", "0");  // 隱藏控制按鈕，同時隱藏標題
        params.append("disablekb", "1"); // 禁用鍵盤控制
        params.append("modestbranding", "1"); // 隱藏 YouTube logo
        params.append("rel", "0");  // 播放完不顯示相關影片
        params.append("autoplay", "1");  // 自動播放影片
        params.append("showinfo", "0"); // 已廢棄，但可測試

        // 檢查是否有 playlist（list 參數）
        const list = urlObj.searchParams.get("list");
        if (list) {
          params.append("list", list);
        }

        // 檢查是否有自動播放的 start_radio 參數
        const startRadio = urlObj.searchParams.get("start_radio");
        if (startRadio) {
          params.append("start_radio", startRadio);
        }

        // 如果有其他 query 參數，將其附加到 URL 中
        if (params.toString()) {
          embedUrl += `?${params.toString()}`;
        }

        return embedUrl;
      } catch (error) {
        console.error("無效的 YouTube 網址：", error.message);
        return null;
      }
    },
  },
  beforeDestroy() {
    this.stopVideo();
    this.intervalId && clearInterval(this.intervalId);
    this.intervalId = null;
  },
};
</script>

<style scoped lang="scss">
.player {
  position: relative;

  .thumbnail {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .thumbnail-wrapper {
    width: 100%;
    height: 0;
    padding-top: 56.25%; // 16:9 的比例
    position: relative;
    overflow: hidden;
  }

  .mask {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
  }

  .button-mask {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .preview-screen {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0);
    color: white;
    z-index: 99;
  }

  .title-wrapper {
    padding: 8px;
    color: white;
  }

  .player-block {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    pointer-events: none;

    iframe {
      width: 100%;
      pointer-events: none;
    }
  }

  .start-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 160px;
    cursor: pointer;
  }

  .buttons {
    width: 100%;
    height: 100%;
  }

  .start-mask {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
  }
}
</style>